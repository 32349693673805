import BaseTextComponent from "@/components/base/el/BaseTextComponent";
import {get_esc_data, get_att_data, is_object} from "@/components/base/utils";
// import {is_array} from "@/components/base/utils"
const YTPlayer = require("@/utils/yt-player.js")

export default {
    name: "BaseVideoComponent",
    mixins: [BaseTextComponent],
    render(createElement) {
        return this.make_element_tree(createElement, this.component_data);
    },
    data() {
        return {
            is_text: false,
            isVideo: true,
            player: '',
            playing: false,
            options: {
                // mute: true,
                // autoplay: true,
                // controls: true
            }
        };
    },
    mounted(){
        if (this.component_data && this.component_data.attr && this.component_data.attr['options']) {
            this.options = JSON.parse(this.component_data.attr['options'].replaceAll("'", '"'))    
        }
        if (!this.$store.state.edit_mode) {
            this.player = new YTPlayer(document.querySelector("div[deepsel-video] div[deepsel-video-iframe]"), {
                controls: this.options.controls,
                autoplay: this.options.autoplay,
                mute: this.options.mute
            })
            this.player.load(this.embed_id,this.options.autoplay)
            this.player.on('playing', () => {
                this.playing = true
            })
            this.player.on('ended', () => {
                this.playing = false
            })
        }
    },
    computed: {
        embed_id() {
            let url_video = this.video_url
            let url = new URL(url_video);
            return url.searchParams.get("v");
        },
        video_url: {
            get () {
                return this.component_data.attr["deepsel-video"]
            },
            set(val) {
                this.component_data.attr["deepsel-video"] = val
            }
        },
        thumbnail: {
            get () {
                if (this.options.thumbnail) {
                    return this.options.thumbnail
                } else {
                    return `https://i.ytimg.com/vi/${this.embed_id}/maxresdefault.jpg`
                }
            },
            set(val) {
                this.options = {...this.options, ...{'thumbnail' : val}}
                this.saveOption()
            }
        },
        controls: {
            get() {
                return this.options.controls ? true : false
            },
            set(val) {
                this.options = {...this.options, ...{'controls' : val}}
                this.saveOption()
            }
        },
        autoplay: {
            get() {
                return this.options.autoplay ? true : false
            },
            set(val) {
                this.options = {...this.options, ...{'autoplay' : val}}
                this.saveOption()
            }
        },
        mute: {
            get() {
                return this.options.mute ? true : false
            },
            set(val) {
                this.options = {...this.options, ...{'mute' : val}}
                this.saveOption()
            }
        },
        data_object() {
            let class_array = [{"deepsel-focus": this.is_editing}]
            if (this.component_data.attr && this.component_data.attr.class) {
                let class_value

                if (Array.isArray(this.component_data.attr.class)) {
                    class_value = this.component_data.attr.class.join(" ")
                } else class_value = this.component_data.attr.class

                class_array.push(class_value)
            }

            return {
                class: class_array,
                attrs: {
                    contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                    draggable: false,
                },
                style: this.styling,
                on: {
                    focusout: this.focus_out,
                    // input: this.on_input,
                    mouseenter: this.mouse_enter,
                    mousedown: this.mouse_down,
                },
            };
        },
    },
    methods: {
        saveOption() {
            this.component_data.attr['options'] = JSON.stringify(this.options)
        },
        focus_out() {
        },
        pauseVideo() {
            this.player.pause()
            this.playing = false
        },
        playVideo() {
            this.player.play()
            this.playing = true
        },
        make_element_tree(createElement, element_data, options = {}, context = null) {
            let data = {}
            let el, props, attrs, style, children

            if (element_data.attr && "deepsel-video" in element_data.attr) {
                let class_array = [{"deepsel-focus": this.is_editing}]
                if (this.component_data.attr && this.component_data.attr.class) {
                    let class_value

                    if (Array.isArray(this.component_data.attr.class)) {
                        class_value = this.component_data.attr.class.join(" ")
                    } else class_value = this.component_data.attr.class

                    class_array.push(class_value)
                }
                data = {
                    class: class_array,
                    attrs: {
                        contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                        draggable: false,
                    },
                    style: this.styling,
                    on: {
                        focusout: this.focus_out,
                        // input: this.on_input,
                        mouseenter: this.mouse_enter,
                        mousedown: this.mouse_down,
                    },
                }
            }
            if (element_data.attr && "deepsel-pause" in element_data.attr) {
                data = {
                    on: {
                        click: () => this.pauseVideo()
                    },
                }
            }
            if (element_data.attr && "deepsel-play" in element_data.attr) {
                data = {
                    on: {
                        click: () => this.playVideo()
                    },
                }
            }

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) data.attrs = {...attrs, ...data.attrs}
            if (style) data.style = {...style, ...data.style}

            for (let key in options) data[key] = options[key]
            
            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data) {
            let el = element_data.tag
            let props = null
            return [el, props]
        },
        get_attrs(element_data) {
            let attrs = {}
            if (element_data.attr) {
                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    if (key === 'type' && element_data.tag === 'button') element_data.attr[key] = 'button'
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }

                if ('deepsel-video-thumbnail' in element_data.attr) {
                    attrs.src = this.thumbnail
                }
                
                if (element_data.tag === "script") {
                    attrs.type = "text/javascript";
                    attrs.defer = false;
                    attrs.async = false;
                }

                let att_data = get_att_data(element_data, this.$store.state.common_data)
                if (att_data) {
                    for (let key in att_data) {
                        attrs[key] = att_data[key]
                    }
                }

                this.replace_origin_urls(attrs, element_data)

                return attrs
            }
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = element_data.attr.style
                if (style.backgroundImage) {
                    if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        replace_origin_urls(attrs, element_data) {
            let origin = this.$store.state.origin;
            if (origin) {
                if (element_data.tag === "script" || element_data.tag === "img") {
                    if (attrs.src) {
                        if (
                            !attrs.src.includes("https://") &&
                            !attrs.src.includes("http://")
                        ) {
                            attrs.src = attrs.src.replace(/\\/g, "/");
                            if (attrs.src.slice(0, 1) !== "/") {
                                attrs.src = "/" + attrs.src;
                            }
                            attrs.src = origin + attrs.src;
                        }
                    }
                } else if (element_data.tag === "link") {
                    if (
                        !attrs.href.includes("https://") &&
                        !attrs.href.includes("http://") &&
                        attrs.href !== '#' &&
                        !attrs.href.includes('javascript:void')
                    ) {
                        attrs.href = attrs.href.replace(/\\/g, "/");
                        if (attrs.href.slice(0, 1) !== "/") {
                            attrs.href = "/" + attrs.href;
                        }
                        attrs.href = origin + attrs.href;
                    }
                }
            }
        },
        get_children(element_data, createElement) {
            let children = []
            let esc_data = get_esc_data(element_data, this.$store.state.common_data)
            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        if (item.tag === "link" && item.attr && item.attr.href) {
                            if (item.attr.href.includes("bootstrap")) continue;
                        }
                        
                        if ('deepsel-video-thumbnail' in item.attr && this.playing) {
                            continue
                        }
                        if ('deepsel-pause' in item.attr && !this.playing) {
                            continue
                        }
                        if ('deepsel-play' in item.attr && this.playing) {
                            continue
                        }
                        children.push(this.make_element_tree(createElement, item));



                    } else if (item.node === "text") {
                        let esc_data = get_esc_data(element_data, this.$store.state.common_data)
                        if (esc_data) {
                            children.push(esc_data)
                        } else children.push(item.text);
                    }
                }
                if (!element_data.child.find(e => e.node === "text") && esc_data) {
                    children = [esc_data]
                }
            } else if (esc_data) {
                children.push(esc_data);
            }

            if (this.transform && "deepsel-video" in element_data.attr) {
                children.push(createElement("style", [this.transform]))
            }
            return children
        }
    }
}